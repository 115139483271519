import Vue from "vue";

export default Vue.extend({
	name: "CollapsePanel",
	props: {
		identifier: {
			type: String,
		},
		hideActions: {
			type: Boolean,
			default: false,
		},
		hideHeaderActions: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		expanded: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	data: () => ({
	}),
	created() {},
	async mounted() {},
	computed: {
		getIdentifier(): string {
			return this.identifier;
		},
		isExpanded(): Boolean {
			return this.expanded;
		},
	},
	methods: {
		getIcon(open: Boolean) {
			if (this.disabled) return "mdi mdi-lock-plus-outline";
			return open ? "mdi-minus-box-outline" : "mdi-plus-box-outline";
		},
		handlerClick(event: any) {
			event?.stopPropagation();
			this.$emit("click", { identifier: this.getIdentifier });
		},
	},
	watch: {},
});
